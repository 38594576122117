.Footer {
  @apply mx-auto px-4 lg:px-12 pt-32 pb-16 w-full text-white;
}

.Footer__content{
  @apply max-w-screen-2xl mx-auto
}

.Footer .Social {
  @apply flex justify-between flex-col items-center md:flex-row lg:items-start;
}

.Footer .Social input {
  @apply rounded-full border-2 py-5;
}

.emailSubmitButton {
  @apply bg-gold-1 text-black rounded-full text-xl px-6 py-2 absolute right-0;
  @apply hover:contrast-75 focus:contrast-75;
}

.Footer .SocialLinks {
  @apply mt-6 flex justify-between w-full md:w-9/12 pl-0 md:pl-6;
}

.Footer .SocialLink {
  @apply border-warning-4 border-2 rounded-full flex justify-center items-center p-5 shadow-md h-6 w-6;
  @apply hover:contrast-75 hover:shadow-lg;
  @apply focus:contrast-75 focus:shadow-lg;
}

.DarkBackgroundFooter {
  background: #23262C;
}

.BottomFooter {
  @apply text-white flex justify-between px-4 md:px-0 pt-28 pb-20 md:pb-12 flex-col-reverse lg:flex-row max-w-screen-2xl mx-auto;
}

.PolicyLink {
  @apply opacity-80 hover:contrast-75 focus:contrast-75 mr-6 xl:mr-0 mb-6 md:mb-0;
}