.MarkdownLayout h1,
h2,
h3,
h4,
p,
ol,
ul,
li,
code {
    @apply mb-2
}

.MarkdownLayout__hero h1{
    @apply leading-[76px] mb-2
}