.SideMenu__logout{
    @apply px-4 py-4 border-t border-t-gray-4 font-semibold mt-auto
}

.SideMenu__username{
    @apply px-4 pt-8 pb-2 mb-2 border-b border-b-gray-4 font-semibold
}

.SideMenu__item{
    @apply block 
}

.SideMenu__item{
    @apply bg-white text-gray-3 inline-flex items-center w-full px-4 py-2 mb-2 rounded-5px cursor-pointer hover:text-gray-0 hover:bg-gray-5;
}

.SideMenu__item--active {
	@apply text-purple-1 bg-purple-5;
}

.SideMenu__item__icon{
    @apply mr-4
}

.SideMenu__item__text{
    @apply text-gray-0;
}

.SideMenu__item--active > .SideMenu__item__text {
    @apply text-purple-1;
}

.SideMenu__item__accordion{
    @apply text-gray-3 text-right w-full
}

.SideMenu__item__accordion__item .SideMenu__item__text{
    @apply pl-4
}