.Accordion {
    @apply flex w-full;
}

.Accordion__button {
    @apply flex text-gray-2;
}

.Accordion__icon {
    @apply transition my-auto;
}

.Accordion__content{
    @apply w-full pl-3 overflow-x-hidden;
}