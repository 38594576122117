.NavigationCardContainer {
  @apply mb-1 bg-white -mt-8 p-0 rounded-sm w-full md:w-3/4 mx-auto max-w-screen-lg relative z-[5] overflow-x-auto;
}

.NavigationCard {
  @apply p-0 rounded-sm w-full min-w-max mx-auto;
}

.NavigationCard__Link {
  @apply flex flex-col md:px-5 pb-3 pt-6 border-t-4 border-t-transparent border-b-4 border-b-transparent hover:border-b-purple-1 hover:text-purple-1 transition-colors;
}

.NavigationCard__Link--isActive {
  @apply border-b-purple-1 text-purple-1;
}

.NavigationCard__Link__Icon {
  @apply mb-2 md:mb-4 leading-none md:text-xl md:leading-none;
}

.NavigationCard__Link__Text {
  @apply md:text-lg font-medium uppercase leading-none md:leading-none;
}

.NavigationCard__Link--isDisabled{
  @apply opacity-50 pointer-events-none
}