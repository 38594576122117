.LandingPageHero{
    @apply h-full md:h-[90vh] text-white
}

.LandingPageHero__content{
    @apply w-[90%] md:w-[80%] mx-auto -mt-10 md:mt-0 pb-10 md:pb-0
}

.LandingPageHero__title{
    @apply font-bold text-3xl md:text-6xl md:leading-[70px]
}

.LandingPageHero__subtitle{
    @apply text-gray-4 font-semibold mt-4
}

.LandingPageHero__date{
    @apply text-gray-4 font-semibold mb-4
}

.LandingPageHero__subtitle__markdown a{
    @apply text-purple-4 underline
}

.LandingPageHero__subtitle__markdown p{
    @apply text-gray-4 font-semibold mt-4 text-xl
}