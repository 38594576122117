.FAQ{
    @apply text-center my-16
}

.FAQ__content{
    @apply w-[80%] md:w-[60%] text-center mx-auto max-h-[320px] overflow-auto
}

.FAQ__accordion{
    @apply px-4 py-2 mt-4 border-b-2 border-b-gray-4 text-left 
}

.FAQ__question{
    @apply text-base md:text-xl font-[500]
}

.FAQ__question--preview{
    @apply w-full text-base md:text-xl whitespace-nowrap overflow-hidden overflow-ellipsis font-[500];
}

.FAQ__answer-markdown p {
    @apply text-[1.25rem] font-medium text-gray-2 py-4;
}

.FAQ__answer-markdown a {
    @apply text-purple-1 hover:underline focus:underline;
}

.FAQ__accordion .Accordion__icon{
    @apply my-0 mt-2 inline-block
}

.FAQ__accordion .Accordion__content{
    @apply w-[98%]
}

.FAQ__discussion_button{
    @apply my-auto mt-12 inline-block px-8 py-2 font-semibold text-black border-2 border-gold-1 rounded-3xl uppercase text-base leading-[28px] hover:opacity-70;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}