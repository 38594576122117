.scroll-top-button {
  @apply duration-300	invisible opacity-0 transition-all h-16 w-16 rounded-full scroll-smooth bg-gray-4 fixed right-7;
}

.scroll-top-button:focus {
  @apply bg-gray-5;
}

.isVisible {
  @apply visible opacity-100;
}
