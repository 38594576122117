.Prizes {
    @apply my-16;
}

.Prizes__content {
    @apply py-8 md:py-16 px-4 lg:px-12 mb-16 mt-12 flex flex-wrap md:flex-nowrap items-stretch mx-auto max-w-screen-2xl
}

.Prizes__content__carousel {
    @apply w-full h-full
}

.Prizes__content__text {
    @apply mb-8 md:mb-0 pt-0 md:pt-16 pr-0 md:pr-8
}

.Prizes__content__text__description p {
    @apply text-2xl font-[500] opacity-60;
}

.Prizes__content__text__description a {
    @apply underline;
}

.Prizes__carousel__item {
    @apply invisible
}

.Prizes__carousel__item.is-selected {
    @apply visible
}

.flickity-page-dots{
    @apply bottom-[-35px]
}