.Registration {
  @apply grid grid-rows-1 lg:grid-rows-1 lg:grid-cols-3 h-full px-0 pt-6 lg:p-12 lg:pt-16 max-w-screen-2xl mx-auto;
}

.Registration__info-wrapper {
  @apply md:col-span-1 lg:col-span-2 md:px-12 lg:pl-0 px-4;
}

.Registration__title, .Registration__description {
  @apply mb-6 lg:mb-10;
}

.Registration__title {
  @apply text-center md:text-start text-2xl md:text-4xl font-semibold;
}

.Registration__description, .Registration__footnotes, .Registration__registered-text {
  @apply text-base md:text-xl;
} 

.Registration__description {
  @apply text-gray-2 font-medium leading-relaxed;
}

.Registration__footnotes, .Registration__registered-text {
  @apply md:px-0 text-gray-1 font-semibold;
}

.Registration__registered-text {
  @apply mt-6;
}

.PLCC-Button--register {
  @apply w-full md:w-auto mt-6 lg:mt-10;
}

.PLCC-Button-Secondary--register {
  @apply text-center mb-6;
}

.Registration__image-wrapper {
  @apply col-span-1 relative h-full max-h-[380px] hidden lg:block;
}

.Registration__image {
  @apply w-full h-full lg:rounded-lg object-cover;
}

.Registration__register-btn-wrapper {
  @apply block md:inline-block transition-opacity;
}

.Registration__subtext{
  @apply text-error-2 text-base md:text-xl font-medium leading-relaxed
}
