@keyframes animatedBackground {
    from {
        background-position: -100px;
    }

    to {
        background-position: 100px;
    }
}

.skyAnimation {
    background-position: 0px 0px;
    background-repeat: repeat-x;
    animation: animatedBackground 10s linear infinite;
    background-attachment: fixed;
}

.zoomElement {
    transition: transform .2s;
}

.zoomElement:hover {
    transform: scale(1.1);
}