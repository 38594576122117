@tailwind components;
@tailwind utilities;


html{
    @apply bg-black min-h-screen;
}

body{
    @apply bg-white;
}

.layout-width-75{
    @apply w-full md:w-3/4 mx-auto max-w-screen-lg pb-8;
}

.Card__content {
    @apply mb-0;
}

.PLCC-Button {
    @apply inline-block px-12 py-3 font-semibold bg-gold-1 text-black rounded-3xl uppercase text-base leading-[28px] hover:opacity-70;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.PLCC-Button-Secondary {
    @apply my-auto inline-block px-8 py-2 font-semibold text-black border-2 border-gold-1 rounded-3xl uppercase text-base leading-[28px] hover:opacity-70;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

/* 
using this to avoid focus state while navigating with mouse, focus states are applied if navigation agent is changed to keyboard 
Refer: https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible 
*/
:focus:not(:focus-visible) {
    outline: 0;
    box-shadow: none;
}

.Button-text:focus:not(:focus-visible) {
    background-color: initial;
    border-color: white;
}