.CountdownSection {
  @apply w-full h-[48vh] md:h-[80vh] py-12 px-4 lg:px-12 bg-cover bg-no-repeat bg-left flex flex-col;
}

.CountdownSection__title {
  @apply m-auto mb-5 text-white text-4xl md:text-6xl font-semibold text-center;
}

.CountdownSection__countdown {
  @apply mb-auto mx-auto mt-2 w-full flex justify-center;
}

.CountdownSection__countdown--times {
  @apply flex justify-between w-[300px] text-white;
}

.CountdownSection__countdown--times .Number {
  @apply font-bold text-4xl;
}

.CountdownSection__countdown--times .NumberLabel {
  @apply text-center -mt-1;
}